import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import P from "../Text/P";

const EmissionsChart = ({ metrics = [], width, slug }) => {
  const [data, setData] = useState(metrics);
  const colors = ["#c3b091", "#64804d", "#777777"];

  let max = 0;

  for (let i = 0; i < metrics.length; i++) {
    if (
      max <
      data[i].ghg_scope_1 +
        data[i].ghg_scope_2_location_based +
        data[i].ghg_scope_3
    ) {
      max =
        data[i].ghg_scope_1 +
        data[i].ghg_scope_2_location_based +
        data[i].ghg_scope_3;
    }
  }

  const svgRef = useRef();
  useEffect(() => {
    const w = width;
    const h = (width * 9) / 16;
    const svg = d3
      .select(svgRef.current)
      .attr("width", w)
      .attr("height", h)
      .style("overflow", "visible");

    const xScale = d3.scaleBand().domain(Object.keys(data)).range([0, w]);

    const yScale = d3
      .scaleLinear()
      .domain([0, max * 1.1])
      .range([h, 0]);

    var z = d3.scaleOrdinal().range(colors);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(metrics.length - 2, 2 + data.length)
      .tickFormat((d, i) => {
        return i + 2018;
      });

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(7)
      .tickFormat((d) => {
        return d / 1000 + "mtCO2e";
      });

    svg
      .append("g")
      .selectAll("g")
      .data(d3.stack().keys(Object.keys(data[0]))(data))
      .enter()
      .append("g")
      .attr("fill", function (d) {
        return z(d.key);
      })
      .selectAll("rect")
      .data(function (d) {
        return d;
      })
      .enter()
      .append("rect")
      .attr("x", function (d, i) {
        return i * (width / data.length) + 10;
      })
      .attr("y", function (d) {
        return yScale(d[1]);
      })
      .attr("height", function (d) {
        return yScale(d[0]) - yScale(d[1]);
      })
      .attr("width", width / data.length - 20);

    svg
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${h})`)
      .attr("font-family", "serif")
      .style("font-family", "serif")
      .append("text");

    svg
      .append("g")
      .call(yAxis)
      .attr("font-family", "serif")
      .style("font-family", "serif")
      .append("text");
  }, [data]);
  return (
    <div>
      <svg
        ref={svgRef}
        style={{
          margin: 30,
          marginTop: 0,
          display: "block",
        }}
      ></svg>
    </div>
  );
};

export default EmissionsChart;
