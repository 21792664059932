import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const FinancesChart = ({ type = "", metrics = [], width, slug }) => {
  const [data, setData] = useState(metrics);
  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  const currencycode =
    slug.split("_")[1] === "us"
      ? "USD"
      : slug.split("_")[1] === "ca"
      ? "CAD"
      : slug.split("_")[1] === "gb"
      ? "GBP"
      : slug.split("_")[1] === "fr"
      ? "EUR"
      : slug.split("_")[1] === "it"
      ? "EUR"
      : slug.split("_")[1] === "de"
      ? "EUR"
      : slug.split("_")[1] === "pt"
      ? "EUR"
      : slug.split("_")[1] === "nl"
      ? "EUR"
      : slug.split("_")[1] === "es"
      ? "EUR"
      : slug.split("_")[1] === "ch"
      ? "CHF"
      : slug.split("_")[1] === "se"
      ? "SEK"
      : slug.split("_")[1] === "dk"
      ? "DKK"
      : slug.split("_")[1] === "jp"
      ? "JPY"
      : slug.split("_")[1] === "cn"
      ? "CNY"
      : slug.split("_")[1] === "in"
      ? "INR"
      : slug.split("_")[1] === "mx"
      ? "MXN"
      : slug.split("_")[1] === "br"
      ? "BRL"
      : slug.split("_")[1] === "au"
      ? "AUD"
      : slug.split("_")[1] === "hk"
      ? "HKD"
      : slug.split("_")[1] === "kr"
      ? "KRW"
      : slug.split("_")[1] === "ng"
      ? "NGN"
      : slug.split("_")[1] === "za"
      ? "ZAR"
      : "USD";

  const PriceCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencycode,
  });

  const Currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const currencySign = String(Currency.format(0))
    .replace(/[0-9]/g, "")
    .replace(".", "");

  const svgRef = useRef();
  useEffect(() => {
    const w = width;
    const h = (width * 9) / 16;
    const svg = d3
      .select(svgRef.current)
      .attr("width", w)
      .attr("height", h)
      .style("overflow", "visible")
      .style("background", "#000000");

    const xScale = d3
      .scaleLinear()
      .domain([0, type === "price" ? data.length : data.length - 1])
      .range([0, w]);

    const yScale = d3
      .scaleLinear()
      .domain([
        Math.min(...data) - average(data) / 10,
        Math.max(...data) + average(data) / 10,
      ])
      .range([h, 0]);

    const generateScaledLine = d3
      .line()
      .x((d, i) => xScale(i))
      .y(yScale)
      .curve(d3.curveLinear);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(type === "price" ? 4 : metrics.length - 2, 2 + data.length)
      .tickFormat((d, i) => {
        if (type === "price") {
          const date = new Date(Date.now() - 2592000000 + 10 * i * 86400000);
          return date.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
          });
        } else {
          return i + 2018;
        }
      });

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(7)
      .tickFormat((d) => {
        if (!!d) {
          d = Number(d);
          d = d.toFixed(2);
          if (type === "price") {
            return PriceCurrency.format(d);
          } else if (type === "is_avg_num_sh_for_eps") {
            const rawNum = String(Currency.format(d))
              .split(".")[0]
              .split("$")[1]
              .replaceAll(",", "");

            if (rawNum.length > 9) {
              return rawNum / 1000000000 + "B";
            } else if (rawNum.length > 6) {
              return rawNum / 1000000 + "M";
            } else {
              return rawNum;
            }
          } else {
            if (!!String(Currency.format(d))) {
              const rawNum = String(Currency.format(d))
                .split(".")[0]
                .split("$")[1]
                .replaceAll(",", "");

              if (rawNum.length > 9) {
                return "$" + rawNum / 1000000000 + "B";
              } else if (rawNum.length > 6) {
                return "$" + rawNum / 1000000 + "M";
              } else {
                return "$" + rawNum;
              }
            }
          }
        }
      });

    svg
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${h})`)
      .attr("font-family", "serif")
      .style("font-family", "serif")
      .append("text");

    svg
      .append("g")
      .call(yAxis)
      .attr("font-family", "serif")
      .style("font-family", "serif")
      .append("text");

    svg
      .selectAll(".line")
      .data([data])
      .join("path")
      .attr("d", (d) => generateScaledLine(d))
      .attr("fill", "none")
      .attr("stroke", "#ffffff");
  }, [data]);
  return (
    <div>
      <svg
        ref={svgRef}
        style={{
          marginLeft:
            type === "price"
              ? 45 +
                Math.floor(Math.max(...data)).toString().length * 5 +
                currencySign.length * 7.5
              : 30,
          marginRight: type === "price" ? 20 : 0,
          marginBottom: type !== "price" ? 40 : 0,
          display: "block",
          fontFamily: "serif",
          color: "#ffffff",
        }}
      ></svg>
    </div>
  );
};

export default FinancesChart;
